import React from 'react'
import { object, func } from 'prop-types'
import { NumberInput, Form, Button, Icon, Color } from 'cobalt-react-components'
import BaseMFA from '../../containers/BaseMFA'
import { MFAHeader, ErrorMessage } from '../../components'
import { LoginContext } from '../../contexts/Login'
import {
  RECOVERY_CODE,
  RECOVERY_CODE_DESCRIPTION,
  INVALID_RECOVERY_OTP_ERROR_MESSAGE,
  GENERIC_RECOVERY_OTP_ERROR_MESSAGE,
  VERIFY
} from '../../constants/ui.labels'
import { RECOVERY_OTP } from '../../constants/mfa.types'
import { STATE_LOADING } from '../../constants/button.states'

export class RecoveryOTP extends BaseMFA {
  handleSubmit = ctx => event => {
    this.setState({ verifyButtonState: STATE_LOADING })
    const mfa = {
      type: RECOVERY_OTP,
      payload: {
        otp: this.state.code
      }
    }

    this.doLogin(this.props.csrf, ctx.credentials, mfa)
  }

  invalidCodeErrorMessage = () => INVALID_RECOVERY_OTP_ERROR_MESSAGE
  genericErrorMessage = () => GENERIC_RECOVERY_OTP_ERROR_MESSAGE

  render() {
    return (
      <React.Fragment>
        <MFAHeader
          icon={Icon.ADMIN}
          iconColor={Color.orange[600]}
          title={RECOVERY_CODE}
          subtitle={RECOVERY_CODE_DESCRIPTION}
        />
        <Form>
          {this.state.showError ? (
            <ErrorMessage message={this.state.errorMessage} />
          ) : (
            <React.Fragment />
          )}
          <Form.Field label={RECOVERY_CODE} disabled={this.state.disabledForm}>
            <NumberInput
              autofocus
              value={this.state.code}
              onChange={this.handleCodeChange}
              placeholder={RECOVERY_CODE}
            />
          </Form.Field>
          <Button
            type={Button.SUBMIT_TYPE}
            disabled={this.isVerifyButtonDisabled()}
            onClick={this.handleSubmit(this.props.ctx)}
            state={this.state.verifyButtonState}
            primary
            fullWidth
          >
            {VERIFY}
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

RecoveryOTP.propTypes = {
  csrf: object.isRequired,
  ctx: object.isRequired,
  redirect: func.isRequired
}

const RecoveryOTPWithContext = props => (
  <LoginContext.Consumer>
    {ctx => <RecoveryOTP ctx={ctx} {...props} />}
  </LoginContext.Consumer>
)
RecoveryOTPWithContext.displayName = 'RecoveryOTP'

export default RecoveryOTPWithContext
