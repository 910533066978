import React from 'react'
import { object, bool, array, func } from 'prop-types'
import { Footnote } from 'cobalt-react-components'
import { Base } from '../../pages'
import { MFAHeader, MFACard } from '../../components'
import {
  MFA_SELECTOR_TITLE,
  MFA_SELECTOR_SUBTITLE,
  MFA_SELECTOR_FOOTNOTE
} from '../../constants/ui.labels'

const MFASelector = ({
  brandingSettings,
  redirect,
  mfas,
  selectMFA,
  changeStep,
  showGoogleFooterLink
}) => {
  return (
    <Base
      brandingSettings={brandingSettings}
      redirect={redirect}
      showGoogleFooterLink={showGoogleFooterLink}
    >
      <MFAHeader title={MFA_SELECTOR_TITLE} subtitle={MFA_SELECTOR_SUBTITLE} />
      {mfas.map(mfa => {
        return (
          <MFACard
            key={mfa.type}
            mfaType={mfa.type}
            selectMFA={selectMFA}
            changeStep={changeStep}
          />
        )
      })}
      <Footnote halfTopSpace microcopy>
        {MFA_SELECTOR_FOOTNOTE}{' '}
      </Footnote>
    </Base>
  )
}

MFASelector.propTypes = {
  brandingSettings: object.isRequired,
  mfas: array.isRequired,
  selectMFA: func.isRequired,
  changeStep: func.isRequired,
  showGoogleFooterLink: bool.isRequired
}

export default MFASelector
