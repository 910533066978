import React from 'react'
import { object } from 'prop-types'
import { TALKDESK_LOGO } from '../../constants/images'
import { LOGO_ALTERNATE_TEXT } from '../../constants/ui.labels'
import { Brand, Image } from 'cobalt-react-components'
import { constantBuilder } from '../../utils/index'

class DisclaimerHeader extends React.PureComponent {
  render() {
    const {
      brandingSettings: {
        branding: { assets, name }
      }
    } = this.props

    return (
      <div className="co--align-center bottom-space">
        {assets && (
          <Brand>
            <Image
              src={TALKDESK_LOGO(assets)}
              alt={constantBuilder(LOGO_ALTERNATE_TEXT, { name })}
              height={32}
              width={160}
            />
          </Brand>
        )}
      </div>
    )
  }
}

DisclaimerHeader.propTypes = {
  brandingSettings: object.isRequired
}

export default DisclaimerHeader
