import React from 'react'
import { string, func } from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'

const Captcha = props => {
  const { siteKey, recaptchaCallback, recaptchaInstance, style } = props

  return (
    <div className="mt10" style={style}>
      <ReCAPTCHA
        sitekey={siteKey}
        onChange={recaptchaCallback}
        ref={recaptchaInstance}
      />
    </div>
  )
}

Captcha.propTypes = {
  siteKey: string.isRequired,
  recaptchaCallback: func.isRequired,
  recaptchaInstance: func.isRequired
}

export default Captcha
