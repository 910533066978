import React from 'react'
import { string } from 'prop-types'
import { Message } from 'cobalt-react-components'

const ErrorMessage = props => {
  const { title, message } = props

  return (
    <Message small danger block>
      {title ? <Message.Title>{title}</Message.Title> : <React.Fragment />}
      {message || props.children ? (
        <React.Fragment>
          {message}
          {props.children}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </Message>
  )
}

Error.propTypes = {
  title: string,
  message: string
}

export default ErrorMessage
