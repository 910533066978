import React from 'react'
import { LOGIN_FORM } from '../../constants/steps'

const FIRST_STEP = LOGIN_FORM

export const LoginContext = React.createContext({
  step: FIRST_STEP,
  loginTimestamp: null,
  mfas: [],
  selectedMFA: {
    type: '',
    data: {}
  },
  credentials: {
    username: '',
    password: ''
  },

  updateCredentials: (prop, val) => {},
  changeStep: step => {},
  setMFAs: mfas => {},
  selectMFA: mfaTypr => {}
})
