const loginPost = (url, body) => {
  return fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    body: body,
    credentials: 'include',
    redirect: 'error',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

export default loginPost
