import React from 'react'
import ReactDOM from 'react-dom'
import CobaltRoot from 'cobalt-react-components'
import RouterRoot from './root'
import { ErrorBoundary } from 'react-error-boundary'

window.TalkdeskApps = window.TalkdeskApps || {}

window.TalkdeskApps.TalkdeskIdLoginUi = {
  start: function(rootElement, params) {
    CobaltRoot.loadBundle()
    startApp(rootElement, params)
  },
  stop: function(rootElement) {
    ReactDOM.unmountComponentAtNode(rootElement)
  },
  version: __APP_VERSION__
}

function startApp(rootElement, params) {
  ReactDOM.render(
    <ErrorBoundary
      onError={(error, errorInfo) =>
        console.log(`Error: ${error}, Error Info: ${errorInfo}`)
      }
    >
      <CobaltRoot>
        <RouterRoot {...params} />
      </CobaltRoot>
    </ErrorBoundary>,
    rootElement
  )
}
