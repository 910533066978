import React from 'react'
import { object, func } from 'prop-types'
import { NumberInput, Form, Button, Icon, Color } from 'cobalt-react-components'
import { MFAHeader, ErrorMessage } from '../../components'
import BaseMFA from '../../containers/BaseMFA'
import { LoginContext } from '../../contexts/Login'
import {
  TOTP_TITLE,
  TOTP_DESCRIPTION,
  INVALID_TOTP_ERROR_MESSAGE,
  GENERIC_TOTP_ERROR_MESSAGE,
  VERIFICATION_CODE,
  VERIFY
} from '../../constants/ui.labels'
import * as MFATypes from '../../constants/mfa.types'
import { STATE_LOADING } from '../../constants/button.states'

export class TOTP extends BaseMFA {
  handleSubmit = ctx => event => {
    this.setState({ verifyButtonState: STATE_LOADING })
    const mfa = {
      type: MFATypes.TOTP,
      payload: {
        totp: this.state.code
      }
    }

    this.doLogin(this.props.csrf, ctx.credentials, mfa)
  }

  invalidCodeErrorMessage = () => INVALID_TOTP_ERROR_MESSAGE
  genericErrorMessage = () => GENERIC_TOTP_ERROR_MESSAGE

  render() {
    return (
      <React.Fragment>
        <MFAHeader
          icon={Icon.PHONELINK_LOCK}
          iconColor={Color.green[600]}
          title={TOTP_TITLE}
          subtitle={TOTP_DESCRIPTION}
        />
        <Form>
          {this.state.showError ? (
            <ErrorMessage message={this.state.errorMessage} />
          ) : (
            <React.Fragment />
          )}
          <Form.Field
            label={VERIFICATION_CODE}
            disabled={this.state.disabledForm}
          >
            <NumberInput
              autofocus
              value={this.state.code}
              onChange={this.handleCodeChange}
              placeholder={VERIFICATION_CODE}
            />
          </Form.Field>
          <Button
            type={Button.SUBMIT_TYPE}
            disabled={this.isVerifyButtonDisabled()}
            onClick={this.handleSubmit(this.props.ctx)}
            state={this.state.verifyButtonState}
            primary
            fullWidth
          >
            {VERIFY}
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

TOTP.propTypes = {
  csrf: object.isRequired,
  ctx: object.isRequired,
  redirect: func.isRequired
}

const TOTPWithContext = props => (
  <LoginContext.Consumer>
    {ctx => <TOTP ctx={ctx} {...props} />}
  </LoginContext.Consumer>
)
TOTPWithContext.displayName = 'TOTP'

export default TOTPWithContext
