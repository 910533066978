import React from 'react'
import { string, bool } from 'prop-types'
import { Color, Icon } from 'cobalt-react-components'
import { SECURITY_KEY_ANIMATION } from '../../../constants/images'
import '../../../../assets/css/web-authn-animation.css'

const WebAuthnLoginAnimation = ({ talkdeskIdAssetsURL, isValid }) => (
  <div className={`svg-animation ${isValid ? 'ok' : ''}`}>
    <div className="co--push-right">
      {/* using an object to force rendering the svg animation, which doesn't reset when re-rendered */}
      <object
        type="image/svg+xml"
        data={SECURITY_KEY_ANIMATION(talkdeskIdAssetsURL)}
        aria-label="webAuthn login"
      />
    </div>
    <div className="svg-success co--push-center">
      <Icon name={Icon.CHECK} xlarge color={Color.green[600]} />
    </div>
  </div>
)

WebAuthnLoginAnimation.defaultProps = {
  isValid: false
}

WebAuthnLoginAnimation.propTypes = {
  talkdeskIdAssetsURL: string.isRequired,
  isValid: bool
}

export default WebAuthnLoginAnimation
