import React from 'react'
import { string } from 'prop-types'
import { H1, Paragraph, Icon, Color } from 'cobalt-react-components'

const MFAHeader = ({ icon, iconColor, title, subtitle }) => (
  <div className="co--align-center bottom-space">
    {icon && <Icon name={icon} color={iconColor} large />}
    <div className="vertical-space">
      <H1 asH3>{title}</H1>
      <Paragraph color={Color.gray[600]}>{subtitle}</Paragraph>
    </div>
  </div>
)

MFAHeader.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  icon: string,
  iconColor: string
}

export default MFAHeader
