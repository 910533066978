export const LOGO_ALTERNATE_TEXT = '__name__'

// Login
export const LOGIN_CREDENTIALS_SUBTITLE = 'Enter your credentials to login'
export const LOGIN_IDENTITY_PROVIDER_SUBTITLE =
  'Use your service provider to login'

export const EMAIL = 'Email'
export const EMAIL_PLACEHOLDER = 'email@company.com'
export const PASSWORD = 'Password'
export const PASSWORD_PLACEHOLDER = 'password'
export const INVALID_CREDENTIALS_ERROR_TITLE =
  'Could not validate your credentials.'
export const INVALID_CREDENTIALS_ERROR_ACTION = 'Need help?'
export const FORGOT_PASSWORD = 'Forgot password?'
export const LOGIN_ACTION = 'Login'
export const OR = 'OR'
export const DEFAULT_SIDE_ILLUSTRATION_TITLE = 'Side Illustration'
export const MARKETING_RESOURCE_TITLE = 'Marketing Resource'

// Footer
export const FOOTER_LICENSE = '__copyright__'
export const SUPPORT = 'Support'
export const TERMS_OF_SERVICE = 'Terms of Service'
export const PRIVACY_POLICY = 'Privacy Policy'
export const GOOGLE_API_SERVICES = 'Google API Services'

// Identity Providers
export const IDENTITY_PROVIDER_LOGIN = 'Login with __identity_provider_name__'

// MFA Page
export const VERIFY = 'Verify'
export const HEADER_ACTION = 'Return to login'
export const MFA_RETRY_ACTION = 'try again'
export const MFA_FOOTNOTE_ACTION = 'Try another way to login'
export const INVALID_CODE_ERROR_MESSAGE = 'Could not validate 2FA'

// Recovery Code
export const RECOVERY_CODE = 'Recovery code'
export const RECOVERY_CODE_DESCRIPTION =
  'Enter one of the recovery codes that were provided to you'
export const INVALID_RECOVERY_OTP_ERROR_MESSAGE = 'The code is invalid'
export const GENERIC_RECOVERY_OTP_ERROR_MESSAGE = 'Could not validate 2FA'

// TOTP
export const TOTP_TITLE = 'Authenticator app'
export const TOTP_DESCRIPTION =
  'Enter the verification code generated by the authenticator application'
export const VERIFICATION_CODE = 'Verification code'
export const INVALID_TOTP_ERROR_MESSAGE = 'The code is invalid'
export const GENERIC_TOTP_ERROR_MESSAGE = 'Could not validate 2FA'

// WEB_AUTHN
export const WEB_AUTHN_TITLE = 'Security Key'
export const WEB_AUTHN_DESCRIPTION =
  'Insert your security key and, if it has a button, tap it'
export const WEB_AUTHN_RETURN_ACTION = 'Return to login'
export const WEB_AUTHN_NOT_SUPPORTED_ERROR_PART1 =
  'An error has occurred, please'
export const WEB_AUTHN_NOT_SUPPORTED_ERROR_PART2 = 'If the issue persists, see'
export const INVALID_WEB_AUTHN_ERROR_MESSAGE =
  'Could not validate security key. Please try again later'
export const HELP = 'help'

// Native app launcher
export const APP_LAUNCH_TITLE = 'We launched __client_name__'
export const APP_LAUNCH_MESSAGE =
  'All set! __client_name__ should have been automatically launched for you'
export const APP_LAUNCH_FOOTNOTE = '__client_name__ did not launch?'
export const APP_LAUNCH_ACTION = 'Retry'

// MFA Selector
export const MFA_SELECTOR_TITLE = 'Try another way to login'
export const MFA_SELECTOR_SUBTITLE = 'Choose one of the authentication methods'
export const MFA_SELECTOR_FOOTNOTE = 'Need help? Contact your admin'
export const MFA_SELECTOR_RECOVERY_OTP_NAME = 'Recovery codes'
export const MFA_SELECTOR_TOTP_NAME = 'Authenticator App'
export const MFA_SELECTOR_WEB_AUTHN_NAME = 'Security Key'
export const MFA_SELECTOR_RECOVERY_OTP_DESCRIPTION =
  'Use the recovery codes to login'
export const MFA_SELECTOR_TOTP_DESCRIPTION =
  'Use the authenticator app to login'
export const MFA_SELECTOR_WEB_AUTHN_DESCRIPTION =
  'Use the security key to login'

//Disclaimer
export const DISCLAIMER_TITLE = 'Talkdesk disclaimer'
export const DISCLAIMER_SUBTITLE = 'Security warning'
export const DISCLAIMER =
  'You are accessing a confidential information system. All system activity may be monitored, recorded, logged and  subject to being audited. Unauthorized use of this system is prohibited. Violations for unauthorized use of, and/or  access to, this system may result in criminal as well as civil penalties. Use of this system indicates your full consent,  and understanding, that your activity while logged into this system may be monitored, recorded, and logged.'
export const DISCLAIMER_ACK = 'Acknowledge'
