import React from 'react'
import queryString from 'query-string'
import { string, bool, object } from 'prop-types'
import { Divider, Link } from 'cobalt-react-components'
import {
  INVALID_CREDENTIALS_ERROR_TITLE,
  INVALID_CREDENTIALS_ERROR_ACTION
} from '../../constants/ui.labels'
import { INVALID_CREDENTIALS_HELP } from '../../constants/links'
import redirect from '../../utils/redirect'
import { OR } from '../../constants/ui.labels'
import Login from '../../containers/Login'
import { IdentityProviders, ErrorMessage } from '../../components'

class LoginBody extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showError: false
    }
  }

  handleErrorChange = () => {
    this.setState({ showError: true })
  }

  setIdentityProvidersDisabled = identityProvidersDisabled => {
    this.setState({ identityProvidersDisabled })
  }

  showError() {
    const queryParameters = queryString.parse(window.location.search) || {}

    return (
      this.state.showError ||
      Object.prototype.hasOwnProperty.call(queryParameters, 'error')
    )
  }

  render() {
    const {
      brandAssetsURL,
      brandingSettings,
      localLoginEnabled,
      csrf,
      showCaptcha,
      siteKey,
      email,
      identityProviders,
      hasIdentityProviders
    } = this.props
    const showError = this.showError()

    return (
      <React.Fragment>
        {showError ? (
          <ErrorMessage>
            <React.Fragment>
              {INVALID_CREDENTIALS_ERROR_TITLE}{' '}
              <Link href={INVALID_CREDENTIALS_HELP(brandingSettings)}>
                {INVALID_CREDENTIALS_ERROR_ACTION}
              </Link>
            </React.Fragment>
          </ErrorMessage>
        ) : (
          <React.Fragment />
        )}
        {localLoginEnabled ? (
          <Login
            csrf={csrf}
            showCaptcha={showCaptcha}
            siteKey={siteKey}
            email={email}
            redirect={redirect}
            handleErrorChange={this.handleErrorChange}
            setIdentityProvidersDisabled={this.setIdentityProvidersDisabled}
          />
        ) : (
          <React.Fragment />
        )}
        {hasIdentityProviders && localLoginEnabled && (
          <div className="container-max-width">
            <Divider>{OR}</Divider>
          </div>
        )}
        {hasIdentityProviders && (
          <IdentityProviders
            identityProviders={identityProviders}
            brandAssetsURL={brandAssetsURL}
            disabled={this.state.identityProvidersDisabled}
          />
        )}
      </React.Fragment>
    )
  }
}

LoginBody.propTypes = {
  brandAssetsURL: string.isRequired,
  brandingSettings: object.isRequired,
  localLoginEnabled: bool.isRequired,
  csrf: object.isRequired,
  showCaptcha: bool.isRequired,
  siteKey: string.isRequired,
  email: string.isRequired,
  identityProviders: object,
  hasIdentityProviders: bool.isRequired
}

export default LoginBody
