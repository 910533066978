export const TALKDESK_LOGO = assets =>
  `${assets['base-url']}${assets.logos.regular.svg}`
export const DEFAULT_SIDE_ILLUSTRATION_PATH = '/login/side-illustration.svg'
export const SIDE_ILLUSTRATION = (talkdeskIdAssetsURL, imageLocation) =>
  `${talkdeskIdAssetsURL}${imageLocation}`
export const SECURITY_KEY_ANIMATION = talkdeskIdAssetsURL =>
  `${talkdeskIdAssetsURL}/mfa/securitykey-animation.svg`

// Identity Providers
export const GOOGLE_LOGO = brandAssetsURL =>
  `${brandAssetsURL}/brands/google/google_icon.svg`
export const MICROSOFT_LOGO = brandAssetsURL =>
  `${brandAssetsURL}/brands/microsoft/microsoft_icon.svg`
export const SALESFORCE_LOGO = brandAssetsURL =>
  `${brandAssetsURL}/brands/salesforce/salesforce_icon.svg`
export const OKTA_LOGO = brandAssetsURL =>
  `${brandAssetsURL}/brands/okta/okta_icon.svg`
export const ONELOGIN_LOGO = brandAssetsURL =>
  `${brandAssetsURL}/brands/onelogin/onelogin_icon.svg`
