import React from 'react'
import { func } from 'prop-types'
import { Button, Grid, Text, Paragraph, H3 } from 'cobalt-react-components'
import {
  DISCLAIMER_TITLE,
  DISCLAIMER_SUBTITLE,
  DISCLAIMER,
  DISCLAIMER_ACK
} from '../../constants/ui.labels'

class DisclaimerBody extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Text microcopy>{DISCLAIMER_TITLE.toUpperCase()}</Text>
        <H3>{DISCLAIMER_SUBTITLE}</H3>
        <Paragraph>
          <span>{DISCLAIMER}</span>
        </Paragraph>
        <Grid.Column pushRight>
          <Button primary onClick={this.props.onAcknowledge}>
            {DISCLAIMER_ACK}
          </Button>
        </Grid.Column>
      </React.Fragment>
    )
  }
}

DisclaimerBody.propTypes = {
  onAcknowledge: func.isRequired
}

export default DisclaimerBody
