import React from 'react'
import { string, bool } from 'prop-types'
import * as identityProviderLogos from '../../constants/images'
import { IDENTITY_PROVIDER_LOGIN } from '../../constants/ui.labels'
import { constantBuilder } from '../../utils'
import { Button, Image, Paragraph, Icon } from 'cobalt-react-components'

const identityProviderLogoSelector = (name, brandAssetsURL) =>
  ({
    Google: identityProviderLogos.GOOGLE_LOGO(brandAssetsURL),
    Microsoft: identityProviderLogos.MICROSOFT_LOGO(brandAssetsURL),
    Salesforce: identityProviderLogos.SALESFORCE_LOGO(brandAssetsURL),
    Okta: identityProviderLogos.OKTA_LOGO(brandAssetsURL),
    OneLogin: identityProviderLogos.ONELOGIN_LOGO(brandAssetsURL)
  }[name])

const IdentityProviderButton = props => {
  const { name, url, brandAssetsURL, redirect, disabled } = props
  const identityProviderLogo = identityProviderLogoSelector(
    name,
    brandAssetsURL
  )

  return (
    <Paragraph>
      <Button
        disabled={disabled}
        secondary
        fullWidth
        iconLeft
        onClick={() => redirect(url)}
      >
        {identityProviderLogo ? (
          <Image src={identityProviderLogo} alt="" />
        ) : (
          <Icon name={Icon.LOCK_OUTLINE} tiny />
        )}
        <span>
          {constantBuilder(IDENTITY_PROVIDER_LOGIN, {
            identity_provider_name: name
          })}
        </span>
      </Button>
    </Paragraph>
  )
}

IdentityProviderButton.propTypes = {
  name: string.isRequired,
  url: string.isRequired,
  brandAssetsURL: string.isRequired,
  disabled: bool
}

export default IdentityProviderButton
