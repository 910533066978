import React from 'react'
import { string, func } from 'prop-types'
import {
  Grid,
  Card,
  Media,
  Color,
  Paragraph,
  H5,
  Icon
} from 'cobalt-react-components'
import * as MFATypes from '../../constants/mfa.types'
import * as MFASteps from '../../constants/steps'
import {
  MFA_SELECTOR_RECOVERY_OTP_NAME,
  MFA_SELECTOR_TOTP_NAME,
  MFA_SELECTOR_WEB_AUTHN_NAME,
  MFA_SELECTOR_RECOVERY_OTP_DESCRIPTION,
  MFA_SELECTOR_TOTP_DESCRIPTION,
  MFA_SELECTOR_WEB_AUTHN_DESCRIPTION
} from '../../constants/ui.labels'
import '../../../assets/css/mfa-selector.css'

const getMFAInfoFromType = mfaType => {
  return {
    [MFATypes.WEB_AUTH]: {
      name: MFA_SELECTOR_WEB_AUTHN_NAME,
      description: MFA_SELECTOR_WEB_AUTHN_DESCRIPTION,
      icon: Icon.SECURITY_KEY,
      color: Color.primary[600]
    },
    [MFATypes.TOTP]: {
      name: MFA_SELECTOR_TOTP_NAME,
      description: MFA_SELECTOR_TOTP_DESCRIPTION,
      icon: Icon.PHONELINK_LOCK,
      color: Color.green[600]
    },
    [MFATypes.RECOVERY_OTP]: {
      name: MFA_SELECTOR_RECOVERY_OTP_NAME,
      description: MFA_SELECTOR_RECOVERY_OTP_DESCRIPTION,
      icon: Icon.ADMIN,
      color: Color.orange[600]
    }
  }[mfaType.toUpperCase()]
}

const MFACard = ({ mfaType, selectMFA, changeStep }) => {
  const mfaInfo = getMFAInfoFromType(mfaType)

  return (
    <Grid.Group verticalGutters={Grid.Group.HALF_VERTICAL_GUTTERS}>
      <Grid.Column all="100">
        <Card
          clickable
          small
          onClick={() => {
            selectMFA(mfaType)
            changeStep(MFASteps.MFA)
          }}
        >
          <Card.Content>
            <Grid noPadding>
              <Grid.Group>
                <Grid.Column alignLeft all="max">
                  <Media alignVerticalCenter={true}>
                    <Icon name={mfaInfo.icon} medium color={mfaInfo.color} />
                    <div className="mfa-card-title">
                      <H5>{mfaInfo.name}</H5>
                    </div>
                    <Paragraph color={Color.gray[600]}>
                      {mfaInfo.description}
                    </Paragraph>
                  </Media>
                </Grid.Column>
                <Grid.Column alignCenter all="min">
                  <div className="co--push-v-center" style={{ height: '100%' }}>
                    <Icon
                      name={Icon.CHEVRON_RIGHT}
                      small
                      color={Color.gray[400]}
                    />
                  </div>
                </Grid.Column>
              </Grid.Group>
            </Grid>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Group>
  )
}

MFACard.propTypes = {
  mfaType: string.isRequired,
  selectMFA: func.isRequired,
  changeStep: func.isRequired
}

export default MFACard
