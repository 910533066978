import React from 'react'
import { bool, object } from 'prop-types'
import { TALKDESK_LOGO } from '../../constants/images'
import { LOGO_ALTERNATE_TEXT } from '../../constants/ui.labels'
import {
  LOGIN_CREDENTIALS_SUBTITLE,
  LOGIN_IDENTITY_PROVIDER_SUBTITLE
} from '../../constants/ui.labels'
import { Brand, Image, Paragraph, Color } from 'cobalt-react-components'
import { constantBuilder } from '../../utils/index'

const LoginHeader = props => {
  const {
    localLoginEnabled,
    brandingSettings: {
      branding: { assets, name }
    }
  } = props

  return (
    <div className="co--align-center bottom-space">
      {assets && (
        <Brand>
          <Image
            src={TALKDESK_LOGO(assets)}
            alt={constantBuilder(LOGO_ALTERNATE_TEXT, { name })}
            height={32}
            width={160}
          />
        </Brand>
      )}
      <Paragraph color={Color.gray[600]}>
        {localLoginEnabled
          ? LOGIN_CREDENTIALS_SUBTITLE
          : LOGIN_IDENTITY_PROVIDER_SUBTITLE}
      </Paragraph>
    </div>
  )
}

LoginHeader.propTypes = {
  localLoginEnabled: bool.isRequired,
  brandingSettings: object.isRequired
}

export default LoginHeader
