import React from 'react'
import { object, bool } from 'prop-types'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { Base } from '../../pages'
import { AppLauncher } from '../../components'
import redirect from '../../utils/redirect'

const getLaunchUrl = search => {
  const params = queryString.parse(search)
  const redirect = params.next
  delete params.next
  if (redirect.includes('?')) {
    return redirect + '&' + queryString.stringify(params)
  }
  return redirect + '?' + queryString.stringify(params)
}

export const AppLauncherPage = props => {
  const {
    metadata: { client },
    brandingSettings,
    showGoogleFooterLink,
    location: { search }
  } = props
  const launchUrl = getLaunchUrl(search)

  redirect(launchUrl)

  return (
    <Base
      brandingSettings={brandingSettings}
      showGoogleFooterLink={showGoogleFooterLink}
    >
      <AppLauncher name={client.name} launchUrl={launchUrl} />
    </Base>
  )
}

AppLauncherPage.propTypes = {
  metadata: object.isRequired,
  brandingSettings: object.isRequired,
  showGoogleFooterLink: bool.isRequired
}

export default withRouter(AppLauncherPage)
