import React from 'react'
import { bool, object, func } from 'prop-types'
import queryString from 'query-string'
import loginPost from '../../api/LoginPost'
import { AUTHENTICATE, ROOT } from '../../constants/links'
import * as HttpStatus from '../../constants/http.codes'
import { STATE_LOADING } from '../../constants/button.states'

class BaseMFA extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      code: '',
      showError: false,
      verifyButtonState: undefined
    }
  }

  isVerifyButtonDisabled() {
    return this.state.disabledForm || !this.state.code
  }

  handleCodeChange = event => {
    this.setState({ code: event.target.value })
  }

  handleError(errorMessage) {
    this.setState({ code: '', showError: true, errorMessage })
  }

  doLogin = (csrf, credentials, mfa) => {
    this.setState({ verifyButtonState: STATE_LOADING, disabledForm: true })
    const data = {
      username: credentials.username,
      password: credentials.password,
      mfa: JSON.stringify(mfa),
      _csrf: csrf.token
    }

    loginPost(AUTHENTICATE, queryString.stringify(data))
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(json => {
              this.props.redirect(json.redirect_uri || ROOT)
            })
            .catch(() => {
              window.location.assign(ROOT)
            })
        } else {
          if (response.status === HttpStatus.UNAUTHORIZED) {
            this.handleError(this.invalidCodeErrorMessage())
          } else {
            this.handleError(this.genericErrorMessage())
          }

          this.setState({ verifyButtonState: null, disabledForm: false })
        }
      })
      .catch(() => {
        this.handleError(this.genericErrorMessage())
        this.setState({ verifyButtonState: null })
      })
  }
}

BaseMFA.propTypes = {
  csrf: object.isRequired,
  showCaptcha: bool.isRequired,
  redirect: func.isRequired
}

export default BaseMFA
