import React from 'react'
import { array, bool, func, object, oneOfType } from 'prop-types'
import { Grid, Section, Page } from 'cobalt-react-components'
import { TalkdeskFooter, TalkdeskNavbar } from '../../components'

const Base = props => {
  return (
    <Page>
      <TalkdeskNavbar
        redirect={props.redirect}
        brandingSettings={props.brandingSettings}
      />
      <Page.Content>
        <Grid gridFluid noPadding fullHeight>
          <Grid.Group fullHeight>
            <Grid.Column all="100" medium="100" small="100">
              <Section fullHeight>
                <Section.Content>
                  <Grid pushCenter>
                    <div className="vertical-space bottom-space container-width container-max-width">
                      {props.children}
                    </div>
                  </Grid>
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Group>
        </Grid>
      </Page.Content>
      <TalkdeskFooter
        brandingSettings={props.brandingSettings}
        showGoogleFooterLink={props.showGoogleFooterLink}
      />
    </Page>
  )
}

Base.propTypes = {
  brandingSettings: object.isRequired,
  redirect: func,
  children: oneOfType([array, object]).isRequired,
  showGoogleFooterLink: bool.isRequired
}

export default Base
