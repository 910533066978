import React from 'react'
import {
  FOOTER_LICENSE,
  SUPPORT,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  GOOGLE_API_SERVICES
} from '../../constants/ui.labels'
import { Color, Footer, Link } from 'cobalt-react-components'
import { constantBuilder } from '../../utils/index'

const TalkdeskFooter = ({
  brandingSettings: { links, branding },
  showGoogleFooterLink
}) => (
  <Footer pushCenter bgColor={Color.background.gray[100]}>
    <small>
      {constantBuilder(FOOTER_LICENSE, {
        copyright: branding.copyright
      })}
    </small>
    <Footer.Navigation>
      <Footer.Link>
        <Link target="_blank" href={links.support}>
          {SUPPORT}
        </Link>
      </Footer.Link>
      <Footer.Link>
        <Link target="_blank" href={links['service-terms']}>
          {TERMS_OF_SERVICE}
        </Link>
      </Footer.Link>
      <Footer.Link>
        <Link target="_blank" href={links['privacy-policy']}>
          {PRIVACY_POLICY}
        </Link>
      </Footer.Link>
      {showGoogleFooterLink && (
        <Footer.Link>
          <Link target="_blank" href={links['google-api-services']}>
            {GOOGLE_API_SERVICES}
          </Link>
        </Footer.Link>
      )}
    </Footer.Navigation>
  </Footer>
)

export default TalkdeskFooter
