import React from 'react'
import { string } from 'prop-types'
import redirect from '../../utils/redirect'
import {
  APP_LAUNCH_TITLE,
  APP_LAUNCH_ACTION,
  APP_LAUNCH_FOOTNOTE
} from '../../constants/ui.labels'
import { constantBuilder } from '../../utils'
import { H1, Paragraph, Icon, Color, Link } from 'cobalt-react-components'

const AppLauncher = ({ name, launchUrl }) => (
  <div className="co--align-center bottom-space">
    <Icon name={Icon.DEVICES} large color={Color.primary[600]} />
    <div className="co--align-center vertical-space bottom-space">
      <H1 asH3>{constantBuilder(APP_LAUNCH_TITLE, { client_name: name })}</H1>
      <Paragraph microcopy={true} color={Color.gray[600]}>
        {constantBuilder(APP_LAUNCH_FOOTNOTE, { client_name: name })}{' '}
        <Link target="_blank" onClick={() => redirect(launchUrl)}>
          {APP_LAUNCH_ACTION}
        </Link>
      </Paragraph>
    </div>
  </div>
)

AppLauncher.propTypes = {
  name: string.isRequired,
  launchUrl: string.isRequired
}

export default AppLauncher
