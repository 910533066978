import React from 'react'
import { object, func } from 'prop-types'
import { Navbar, Brand, Image, Button } from 'cobalt-react-components'
import { HEADER_ACTION, LOGO_ALTERNATE_TEXT } from '../../constants/ui.labels'
import { ROOT } from '../../constants/links'
import { TALKDESK_LOGO } from '../../constants/images'
import { constantBuilder } from '../../utils/index'

const TalkdeskNavbar = ({ brandingSettings: { branding }, redirect }) => {
  return (
    <Navbar shadow>
      <Navbar.Primary>
        {branding.assets && (
          <Navbar.Container pushLeft>
            <Brand href={ROOT}>
              <Image
                src={TALKDESK_LOGO(branding.assets)}
                alt={constantBuilder(LOGO_ALTERNATE_TEXT, {
                  name: branding.name
                })}
                width={100}
                height={20}
              />
            </Brand>
          </Navbar.Container>
        )}
        {redirect && (
          <Navbar.Container pushRight>
            <Navbar.Actions>
              <Button onClick={() => redirect()} secondary small>
                {HEADER_ACTION}
              </Button>
            </Navbar.Actions>
          </Navbar.Container>
        )}
      </Navbar.Primary>
    </Navbar>
  )
}

TalkdeskNavbar.propTypes = {
  brandingSettings: object.isRequired,
  redirect: func
}

export default TalkdeskNavbar
