import React from 'react'
import { string, number } from 'prop-types'
import { Image } from 'cobalt-react-components'
import {
  SIDE_ILLUSTRATION,
  DEFAULT_SIDE_ILLUSTRATION_PATH
} from '../../constants/images'
import {
  DEFAULT_SIDE_ILLUSTRATION_TITLE,
  MARKETING_RESOURCE_TITLE
} from '../../constants/ui.labels'

const Banner = ({ children }) => (
  <div
    style={{ overflowY: 'hidden' }}
    className="co--bg-gray-200 co--full-height"
  >
    {children}
  </div>
)

const MARKETING_RESOURCE_LOAD_EVENT = 'ResourceLoaded'

class SideIllustration extends React.PureComponent {
  static propTypes = {
    marketingBannerTimeout: number.isRequired,
    marketingBannerURL: string.isRequired,
    talkdeskIdAssetsURL: string.isRequired
  }

  state = {
    showPlaceholder: true,
    iframeLoaded: false
  }

  timer = null

  componentDidMount() {
    window.addEventListener('message', this.onIframeLoadEventReceived)
  }

  componentWillUnmount = () => this.cleanup()

  cleanup() {
    window.removeEventListener('message', this.onIframeLoadEventReceived)
    clearTimeout(this.timer)
    this.timer = null
  }

  onIframeLoadEventReceived = event => {
    if (
      event.data === MARKETING_RESOURCE_LOAD_EVENT &&
      this.state.showPlaceholder
    ) {
      this.cleanup()
      this.setState({ iframeLoaded: true, showPlaceholder: false })
    }
  }

  onIframeLoad = () => {
    if (!this.state.iframeLoaded) {
      this.timer = setTimeout(() => {
        this.cleanup()
        this.setState({ showPlaceholder: false })
      }, this.props.marketingBannerTimeout)
    }
  }

  getIframeStyle = () => {
    const { showPlaceholder, iframeLoaded } = this.state
    const showIframe = !showPlaceholder && iframeLoaded
    const opacity = showIframe
      ? {
          opacity: 1,
          transition: 'opacity 500ms'
        }
      : {
          opacity: 0
        }
    const percentage = showIframe ? '100%' : '0%'
    const dimensions = {
      width: percentage,
      height: percentage
    }

    return { ...dimensions, ...opacity }
  }

  renderIframe = () => (
    <iframe
      title={MARKETING_RESOURCE_TITLE}
      src={this.props.marketingBannerURL}
      frameBorder="0"
      style={this.getIframeStyle()}
      onLoad={this.onIframeLoad}
    />
  )

  renderImage = (
    path = DEFAULT_SIDE_ILLUSTRATION_PATH,
    title = DEFAULT_SIDE_ILLUSTRATION_TITLE
  ) => {
    const { talkdeskIdAssetsURL } = this.props
    const imageLocation = SIDE_ILLUSTRATION(talkdeskIdAssetsURL, path)

    return <Image src={imageLocation} alt={title} flexible />
  }

  render = () => {
    const { showPlaceholder, iframeLoaded } = this.state
    const renderContent =
      !showPlaceholder && !iframeLoaded ? this.renderImage : this.renderIframe
    return <Banner>{renderContent()}</Banner>
  }
}

export default SideIllustration
