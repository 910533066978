import React from 'react'
import { string, object, func, number } from 'prop-types'
import { get, supported } from '@github/webauthn-json'
import { Link, Icon, Color, Box, Grid } from 'cobalt-react-components'
import { MFAHeader, ErrorMessage } from '..'
import WebAuthnLoginAnimation from './WebAuthnLoginAnimation'
import { WEB_AUTH as WebAuthnType } from '../../constants/mfa.types'
import BaseMFA from '../../containers/BaseMFA'
import { LoginContext } from '../../contexts/Login'
import {
  WEB_AUTHN_TITLE,
  WEB_AUTHN_DESCRIPTION,
  WEB_AUTHN_RETURN_ACTION,
  WEB_AUTHN_NOT_SUPPORTED_ERROR_PART1,
  WEB_AUTHN_NOT_SUPPORTED_ERROR_PART2,
  INVALID_WEB_AUTHN_ERROR_MESSAGE,
  HELP,
  MFA_RETRY_ACTION
} from '../../constants/ui.labels'
import { LEARN_MORE_ABOUT_SECURITY_KEYS } from '../../constants/links'
import '../../../assets/css/security-key-box.css'

export class WebAuthn extends BaseMFA {
  constructor(props) {
    super(props)

    this.state = {
      initialTimeSeconds: this.currentTimeSeconds(),
      data: props.data,
      showError: false
    }
  }

  retry = () => {
    this.setState({
      showError: false
    })
    setTimeout(this.signIfSupported, 500)
  }

  webAuthnNotSupportedError = () => (
    <React.Fragment>
      {WEB_AUTHN_NOT_SUPPORTED_ERROR_PART1}{' '}
      <Link onClick={this.retry}>{MFA_RETRY_ACTION}</Link>
      {'. ' + WEB_AUTHN_NOT_SUPPORTED_ERROR_PART2 + ' '}
      <Link
        target="_blank"
        href={LEARN_MORE_ABOUT_SECURITY_KEYS(this.props.brandingSettings)}
      >
        {HELP}
      </Link>
    </React.Fragment>
  )

  ensureSupported = () =>
    new Promise((resolve, reject) => {
      if (supported()) {
        resolve()
      } else {
        reject()
      }
    }).catch(() => {
      this.setState({
        errorMessage: this.webAuthnNotSupportedError(),
        showError: true
      })
    })

  componentDidMount() {
    this.mounted = true

    this.signIfSupported()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  signIfSupported = () => this.ensureSupported().then(this.sign)

  currentTimeSeconds = () => new Date().getTime() / 1000

  calculateRemainingTtl = () =>
    this.props.loginTimestamp +
    this.props.webAuthnTokenTtl -
    this.props.webAuthnTokenTtlHeadroomSeconds -
    this.currentTimeSeconds()

  sign = () => {
    const publicKeyCredential = this.props.data.assertionRequest
      .publicKeyCredentialRequestOptions
    publicKeyCredential.timeout = this.calculateRemainingTtl()
    get({
      publicKey: publicKeyCredential
    }).then(
      this.doWhenMounted(this.handleSignSuccess),
      this.doWhenMounted(this.handleSignError)
    )
  }

  doWhenMounted = fn => data => {
    if (this.mounted) return fn(data)
  }

  finalizeAuthentication = data => {
    const mfa = {
      type: WebAuthnType,
      payload: {
        requestId: this.props.data.requestId,
        credential: data
      }
    }

    this.doLogin(this.props.csrf, this.props.ctx.credentials, mfa)
  }

  handleSignSuccess = data => {
    this.setState({ showError: false, showSuccess: true })
    this.finalizeAuthentication(data)
  }

  handleSignError = () => {
    this.setState({
      errorMessage: this.webAuthnNotSupportedError(),
      showError: true
    })
  }

  invalidCodeErrorMessage = () => INVALID_WEB_AUTHN_ERROR_MESSAGE

  renderMessageWithAction = message => (
    <React.Fragment>
      {message}{' '}
      <Link onClick={() => this.props.redirect()}>
        {WEB_AUTHN_RETURN_ACTION}
      </Link>
    </React.Fragment>
  )

  render() {
    const { talkdeskIdAssetsURL } = this.props

    return (
      <React.Fragment>
        <MFAHeader
          icon={Icon.SECURITY_KEY}
          iconColor={Color.primary[600]}
          title={WEB_AUTHN_TITLE}
          subtitle={WEB_AUTHN_DESCRIPTION}
        />
        {this.state.showError ? (
          <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
        ) : (
          <React.Fragment />
        )}
        <div className="security-key">
          <Box>
            <Box.Content>
              <Grid noPadding>
                <div className="security-key-box">
                  <Grid.Group fullHeight>
                    <Grid.Column pushCenter>
                      <WebAuthnLoginAnimation
                        talkdeskIdAssetsURL={talkdeskIdAssetsURL}
                        isValid={this.state.showSuccess}
                      />
                    </Grid.Column>
                  </Grid.Group>
                </div>
              </Grid>
            </Box.Content>
          </Box>
        </div>
      </React.Fragment>
    )
  }
}

WebAuthn.propTypes = {
  talkdeskIdAssetsURL: string.isRequired,
  csrf: object.isRequired,
  ctx: object.isRequired,
  redirect: func.isRequired,
  data: object.isRequired,
  webAuthnTokenTtl: number.isRequired,
  loginTimestamp: number.isRequired,
  webAuthnTokenTtlHeadroomSeconds: number
}

WebAuthn.defaultProps = {
  webAuthnTokenTtlHeadroomSeconds: 5
}

const WebAuthWithContext = props => (
  <LoginContext.Consumer>
    {ctx => <WebAuthn ctx={ctx} {...props} />}
  </LoginContext.Consumer>
)
WebAuthWithContext.displayName = 'WEB_AUTHN'

export default WebAuthWithContext
