const emptyBrandingSettings = {
  branding: {
    copyright: '',
    name: ''
  },
  links: {
    'service-terms': '',
    'privacy-policy': '',
    support: '',
    'google-api-services': ''
  }
}

export default emptyBrandingSettings
