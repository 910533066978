import React from 'react'
import { object, string, bool } from 'prop-types'
import IdentityProviderButton from '../IdentityProviderButton'
import redirect from '../../utils/redirect'

const IdentityProviders = props => {
  const { identityProviders, brandAssetsURL, disabled } = props

  return Object.entries(identityProviders).map(([name, url], index) => {
    return (
      <IdentityProviderButton
        key={index}
        name={name}
        url={url}
        brandAssetsURL={brandAssetsURL}
        redirect={redirect}
        disabled={disabled}
      />
    )
  })
}

IdentityProviders.propTypes = {
  identityProviders: object.isRequired,
  brandAssetsURL: string.isRequired,
  disabled: bool
}

export default IdentityProviders
