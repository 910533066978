import React from 'react'
import { object, string, func } from 'prop-types'
import queryString from 'query-string'
import loginPost from '../../api/LoginPost'
import { LoginContext } from '../../contexts/Login'
import { Captcha } from '../../components'
import { MFA } from '../../constants/steps'
import { STATE_LOADING } from '../../constants/button.states'
import * as HttpStatus from '../../constants/http.codes'
import {
  EMAIL,
  EMAIL_PLACEHOLDER,
  PASSWORD,
  PASSWORD_PLACEHOLDER,
  FORGOT_PASSWORD,
  LOGIN_ACTION
} from '../../constants/ui.labels'
import { RECOVER_PASSWORD, AUTHENTICATE, ROOT } from '../../constants/links'
import { Form, Button, Link, TextInput } from 'cobalt-react-components'

export class Login extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = {
      username: this.props.email,
      password: '',
      validUsername: this.props.email !== '',
      validPassword: false,
      recaptchaResponse: '',
      disableForm: false
    }

    this.state = {
      ...this.initialState,
      showCaptcha: this.props.showCaptcha,
      recaptchaInstance: undefined,
      loginButtonState: undefined
    }
  }

  handleUsernameChange = ctx => event => {
    const username = event.target.value
    ctx.updateCredentials('username', username)
    this.setState({ username, validUsername: !!username.length })
  }

  handlePasswordChange = ctx => event => {
    const password = event.target.value
    ctx.updateCredentials('password', password)
    this.setState({
      password,
      validPassword: !!password.length
    })
  }

  isLoginButtonDisabled() {
    return (
      this.state.disableForm ||
      !(
        this.state.validUsername &&
        this.state.validPassword &&
        (!this.state.showCaptcha ||
          (this.state.showCaptcha && this.state.recaptchaResponse) ||
          (this.state.showCaptcha && this.props.siteKey === ''))
      )
    )
  }

  handleError = showCaptcha => {
    if (this.state.recaptchaResponse) {
      this.resetRecaptcha()
    }

    this.props.handleErrorChange()
    this.props.setIdentityProvidersDisabled(false)

    this.setState(state => {
      return {
        ...this.initialState,
        loginButtonState: null,
        disableForm: false,
        showCaptcha: showCaptcha || state.showCaptcha
      }
    })
  }

  recaptchaCallback = response => {
    this.setState(state => {
      return {
        recaptchaResponse: response
      }
    })
  }

  recaptchaInstance = e => this.setState({ recaptchaInstance: e })

  resetRecaptcha = () => this.state.recaptchaInstance.reset()

  handleLoginResponse = ctx => response => {
    if (response.ok) {
      response
        .json()
        .then(json => {
          this.props.redirect(json.redirect_uri || ROOT)
        })
        .catch(error => {
          window.location.assign(ROOT)
        })
    } else if (response.status === HttpStatus.UNAUTHORIZED) {
      response
        .json()
        .then(json => {
          if (json.redirect_uri) {
            this.props.redirect(json.redirect_uri || ROOT)
          } else if (json.enabled_mfa && json.enabled_mfa.length) {
            ctx.setMFAs(json.enabled_mfa)
            ctx.changeStep(MFA)
          } else {
            this.handleError(json.show_captcha)
          }
        })
        .catch(error => this.handleError())
    } else {
      this.handleError()
    }
  }

  handleSubmit = ctx => event => {
    this.setState({ loginButtonState: STATE_LOADING, disableForm: true })
    this.props.setIdentityProvidersDisabled(true)

    const { username, password, recaptchaResponse } = this.state

    let data = {
      username: username,
      password: password,
      _csrf: this.props.csrf.token
    }

    if (recaptchaResponse) {
      data['g-recaptcha-response'] = recaptchaResponse
    }

    loginPost(AUTHENTICATE, queryString.stringify(data))
      .then(this.handleLoginResponse(ctx))
      .catch(() => {
        this.handleError()
        this.setState({ loginButtonState: null })
      })
  }

  render() {
    return (
      <Form>
        <Form.Field label={EMAIL} disabled={this.state.disableForm}>
          <TextInput
            value={this.state.username}
            tabIndex={'0'}
            autoFocus
            onChange={this.handleUsernameChange(this.props.ctx)}
            type={TextInput.TYPE_EMAIL}
            placeholder={EMAIL_PLACEHOLDER}
          />
        </Form.Field>
        <Form.Field
          label={PASSWORD}
          disabled={this.state.disableForm}
          tertiaryLabel={
            <Link tabIndex={'1'} href={RECOVER_PASSWORD}>
              {FORGOT_PASSWORD}
            </Link>
          }
        >
          <TextInput
            tabIndex={'0'}
            value={this.state.password}
            onChange={this.handlePasswordChange(this.props.ctx)}
            type={TextInput.TYPE_PASSWORD}
            placeholder={PASSWORD_PLACEHOLDER}
          />
        </Form.Field>

        {this.state.showCaptcha && this.props.siteKey !== '' ? (
          <Form.Field>
            <Captcha
              siteKey={this.props.siteKey}
              recaptchaCallback={this.recaptchaCallback}
              recaptchaInstance={this.recaptchaInstance}
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          </Form.Field>
        ) : (
          <React.Fragment />
        )}
        <Form.Field disabled={this.isLoginButtonDisabled()}>
          <Button
            tabIndex={'0'}
            type={Button.SUBMIT_TYPE}
            onClick={this.handleSubmit(this.props.ctx)}
            state={this.state.loginButtonState}
            primary
            fullWidth
          >
            {LOGIN_ACTION}
          </Button>
        </Form.Field>
      </Form>
    )
  }
}

Login.propTypes = {
  ctx: object.isRequired,
  siteKey: string.isRequired,
  redirect: func.isRequired,
  handleErrorChange: func.isRequired,
  setIdentityProvidersDisabled: func.isRequired,
  email: string.isRequired
}

const LoginWithContext = props => (
  <LoginContext.Consumer>
    {ctx => <Login ctx={ctx} {...props} />}
  </LoginContext.Consumer>
)
LoginWithContext.displayName = 'Login'

export default LoginWithContext
