import React from 'react'
import { string, object, func, number, bool } from 'prop-types'
import { Footnote, Link } from 'cobalt-react-components'
import { Base } from '../../pages'
import { RecoveryOTP, TOTP, WebAuthn } from '../../components'
import { MFA_FOOTNOTE_ACTION } from '../../constants/ui.labels'
import * as MFATypes from '../../constants/mfa.types'
import { MFA_SELECTOR } from '../../constants/steps'

const getMFAMechanism = ({
  mfa,
  metadata,
  loginTimestamp,
  talkdeskIdAssetsURL,
  ...props
}) =>
  ({
    [MFATypes.RECOVERY_OTP]: <RecoveryOTP {...props} />,
    [MFATypes.TOTP]: <TOTP {...props} />,
    [MFATypes.WEB_AUTH]: (
      <WebAuthn
        data={mfa.data}
        webAuthnTokenTtl={
          metadata && metadata.webAuthn ? metadata.webAuthn.ttl : null
        }
        loginTimestamp={loginTimestamp}
        talkdeskIdAssetsURL={talkdeskIdAssetsURL}
        {...props}
      />
    )
  }[mfa.type.toUpperCase()])

const MFA = props => (
  <Base
    brandingSettings={props.brandingSettings}
    redirect={props.redirect}
    showGoogleFooterLink={props.showGoogleFooterLink}
  >
    {getMFAMechanism(props)}
    <Footnote halfTopSpace microcopy>
      <Link onClick={() => props.changeStep(MFA_SELECTOR)}>
        {MFA_FOOTNOTE_ACTION}
      </Link>
    </Footnote>
  </Base>
)

MFA.propTypes = {
  redirect: func.isRequired,
  changeStep: func.isRequired,
  brandingSettings: object.isRequired,
  talkdeskIdAssetsURL: string.isRequired,
  csrf: object.isRequired,
  mfa: object,
  loginTimestamp: number,
  metadata: object,
  showGoogleFooterLink: bool.isRequired
}

export default MFA
